import { BarChart } from "../components/BarChart";
import {useState, useMemo} from "react";
import CustomDateRangePicker from "../components/DateRangePicker";
import {useQuery} from "react-query";
import statisticsService from "../services/statistics.service";
import formService from "../services/form.service";

function HopperPage() {
  const endDate = new Date();
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  endDate.setMilliseconds(59);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
    endDate: endDate,
    key: "selection",
  });

  const {data: statesData} = useQuery(
    {
      queryKey: ['states'],
      queryFn: () => formService.getSteps(),
    }
  )

  const {data, isSuccess} = useQuery(
    {
      queryKey: ['hopper', selectionRange],
      queryFn: () => statisticsService.getSteps(selectionRange),
    }
  )

  const preparedData = useMemo(
    () => {
      const res1 = {}

      if (isSuccess) {
        statesData?.data.forEach((state) => {
          if (Object.values(data?.data).filter((obj) => obj.progress == state.progress_index).length
          ) {
            res1[state.name] = Object.values(data?.data).filter(
              (obj) => obj.progress == state.progress_index
            )[0].count;
          } else res1[state.name] = 0;
        })
      }

      return {
        labels: Object.keys(res1),
        datasets: [
          {
            label: "Пользователи",
            data: Object.values(res1),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      }
    },
    [statesData?.data, data?.data, isSuccess]
  )

  const labels = [
    "Шаг 1",
    "Шаг 2",
    "Шаг 3",
    "Шаг 4",
    "Шаг 5",
    "Шаг 6",
    "Шаг 7",
    "Шаг 8",
    "Шаг 9",
  ];
  return (
    <div style={{ display: "flex", gap: "40px"}}>
      <BarChart labels={labels} data={preparedData} />
      <CustomDateRangePicker
        selectionRange={selectionRange}
        setSelectionRange={setSelectionRange}
      />
    </div>
  );
}

export default HopperPage;
