import {axiosWithAuth} from "../api/interceptors";


export default class messageService {
  static async broadcast(message) {
    const data = {
      user_id: "@everyone",
      text: message,
    };
    return axiosWithAuth.post(`/message/user`, data);
  }
}
