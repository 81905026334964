import CustomTable from "../components/CustomTable";
import CustomDateRangePicker from "../components/DateRangePicker";
import {useMemo, useState} from "react";
import Button from "@mui/material/Button";
import {useQuery} from "react-query";
import formService from "../services/form.service";
import contractorService from "../services/contractor.service";
import {Pagination} from "@mui/material";

function TableEl({ selectionRange}) {
  const labels = {
    id: "ID анкеты",
    sent: "Создано заявок",
    pending: "Ожидает",
    approved: "Одобрено",
    rejected: "Отклонено",
  };

  const [page, setPage] = useState(1);

  const {data} = useQuery(
    ["forms", page, selectionRange],
    () => formService.getAll(page, selectionRange),
  )

  const {data: contractorsData, isSuccess: contractorsIsSuccess} = useQuery(
    {queryKey: ["contractors"],
      queryFn: () => contractorService.getAll()}
  )

  const contractors = useMemo(
    () => {
      if (!contractorsIsSuccess) return []
      return contractorsData?.data.map((contractor) => ({
        id: contractor.id,
        name: contractor.name,
      }))
    }, [contractorsIsSuccess, contractorsData?.data]
  )

  const forms = useMemo(
    () => {
      if (!data) return []
      return data?.data.results.map((form) => ({
        ...form,
        sent: form.stats?.sent,
        pending: form.stats?.pending,
        approved: form.stats?.approved,
        rejected: form.stats?.rejected,
        contractor: contractors.find((contractor) => contractor.id === form.contractor_id)?.name,
      }))
    }, [data, contractors]
  )

  return (
    <div style={{display: "flex", flexDirection: "column", gap: "16px"}}>
      {data?.data.total > 0 ? (
        <CustomTable touchable={true} to="app" labels={labels} data={forms}/>
      ) : (
        <h1>Нет анкет</h1>
      )}
      <div style={{display: "flex", justifyContent: "center"}}>
        <Pagination count={data?.data.max_page} page={page} onChange={(event, page) => setPage(page)}/>
      </div>
    </div>
  );
}

function StatsPage() {
  const endDate = new Date();
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  endDate.setMilliseconds(59);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
    endDate: endDate,
    key: "selection",
  });
  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "40px" }}>
      <div style={{ width: "80%" }}>
        <TableEl
          selectionRange={selectionRange}
        />
      </div>

      <CustomDateRangePicker
        selectionRange={selectionRange}
        setSelectionRange={setSelectionRange}
      />
    </div>
  );
}

export default StatsPage;
