import { axiosWithAuth } from "../api/interceptors";
import dayjs from "dayjs";

export default class statisticsService {
  static async getSteps(selectionRange) {
    return axiosWithAuth.get(`/statistics/form/progress/date-range`, {
      params: {
        start_date: dayjs(new Date(selectionRange.startDate)).format("YYYY-MM-DD"),
        end_date: dayjs(new Date(selectionRange.endDate)).format("YYYY-MM-DD"),
      }
    })
  }
  static async getIncomingTraffic(selectionRange) {
    return axiosWithAuth.get(`/statistics/user/date-range`, {
      params: {
        start_date: dayjs(new Date(selectionRange.startDate)).format("YYYY-MM-DD"),
        end_date: dayjs(new Date(selectionRange.endDate)).format("YYYY-MM-DD"),
      }
    })
  }
}
