import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import messageService from "../services/message.service";

function BroadcastPage() {
  const [message, setMessage] = useState("");

  const handleChange = (val) => {
    setMessage(val);
  };

  const handleConfirm = () => {
    if (message == "") alert("Сообщение пустое");
    else {
      const userConfirmed = window.confirm(
        "Вы уверены, что хотите отправить сообщение?"
      );
      if (userConfirmed) {
        messageService.broadcast(message).then((res) => {
          if (res) {
            alert("Сообщение отправлено успешно!");
            setMessage("");
          } else {
            alert("Произошла ошибка при отправке сообщения!");
          }
        });
      }
    }
  };

  return (
    <div>
      <div
        style={{
          width: "20%",
          display: "flex",
          flexDirection: "column",
          gap: 20,
          alignItems: "start",
        }}
      >
        <TextField
          id="outlined-textarea"
          label="Сообщение"
          placeholder="Введите сообщение"
          multiline
          fullWidth
          value={message}
          onChange={(e) => handleChange(e.target.value)}
        />
        <Button onClick={() => handleConfirm()} variant="outlined">
          Отправить
        </Button>
      </div>
    </div>
  );
}

export default BroadcastPage;
