import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { useNavigate } from "react-router-dom";

export default function CustomTable({ labels, data, to, touchable }) {
  const navigate = useNavigate();
  const handleNavigation = (row) => {
    touchable && navigate(`/${to}/${row.id}`);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {Object.values(labels).map((text, index) => {
              return index == 0 ? (
                <TableCell align="left">{text}</TableCell>
              ) : (
                <TableCell align="center">{text}</TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              onClick={() => {
                handleNavigation(row);
              }}
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {Object.keys(labels).map((text, index) => {
                return index == 0 ? (
                  <TableCell align="left" component="th" scope="row">
                    {row[text]}
                  </TableCell>
                ) : (
                  <TableCell align="center">{row[text]}</TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
