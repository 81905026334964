import Cookies from "js-cookie";


export const getAccessToken = () => {
  const accessToken = Cookies.get("accessToken");
  return accessToken || null;
};

export const saveTokenStorage = (accessToken) => {
  Cookies.set("accessToken", accessToken, {
    domain: process.env.NEXT_PUBLIC_COOKIE_URL,
    sameSite: "strict",
    expires: 0.0416
  });
};

export const removeFromStorage = () => {
  Cookies.remove("accessToken");
};
