import React, {useEffect, useState} from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid, Pagination
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useQuery, useQueryClient} from "react-query";
import showcaseService from "../services/showcase.service";
import TextField from "@mui/material/TextField";


const ShowcaseCard = ({showcase}) => {
  const queryClient = useQueryClient()
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDelete = () => {
    showcaseService.delete(showcase.id).then(
      () => {
        queryClient.invalidateQueries("showcases").then();
      }
    )
  }

  return (
    <>
      <Card sx={{ maxWidth: 345, padding: "8px" }}>
        <CardContent style={{textAlign: "left"}}>
          <Typography gutterBottom variant="h5" component="div">
            {showcase.name}
          </Typography>
          <Typography variant="body2">
            Сумма займа: {showcase.loan_amount}
          </Typography>
          <Typography variant="body2">
            Процентная ставка: {showcase.rate}%
          </Typography>
          <Typography variant="body2">
            Ссылка: {showcase.url}
          </Typography>
        </CardContent>
        <CardActions>
          <Button variant="contained" size="small" onClick={handleClickOpen}>
            Редактировать
          </Button>
          <Button variant="contained" color="error" size="small" onClick={onDelete}>
            Удалить
          </Button>
        </CardActions>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData).entries());
            const name = formJson.name;
            const loanAmount = formJson.loan_amout;
            const rate = formJson.rate;
            const url = formJson.url;
            showcaseService.update(showcase.id, name, loanAmount, rate, url).then(
              () => {
                queryClient.invalidateQueries("showcases").then(
                  () => handleClose()
                );
              }
            )
          },
        }}
      >
        <DialogTitle>Редактирование</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            defaultValue={showcase.name}
            margin="dense"
            id="name"
            name="name"
            label="Название"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            defaultValue={showcase.loan_amount}
            margin="dense"
            id="loan_amout"
            name="loan_amout"
            type="number"
            label="Сумма займа"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            defaultValue={showcase.rate}
            margin="dense"
            id="rate"
            name="rate"
            type="number"
            label="Процентная ставка"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            defaultValue={showcase.url}
            margin="dense"
            id="url"
            name="url"
            label="Ссылка"
            fullWidth
            variant="standard"
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отменить</Button>
          <Button type="submit">Сохранить</Button>
        </DialogActions>
      </Dialog>
    </>

  );
}

const ShowcasesPage = () => {
  const queryClient = useQueryClient()
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [timerValue, setTimerValue] = useState(15);
  const [headerValue, setHeaderValue] = useState("Витрина!");

  const {data} = useQuery(
    ["showcases", page],
    () => showcaseService.getAll(page)
  )

  const {data: dataConfig, isSuccess} = useQuery(
    ["showcaseConfig"],
    () => showcaseService.getConfiguration()
  )

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isSuccess) {
      setTimerValue(dataConfig?.data.timer);
      setHeaderValue(dataConfig?.data.header);
    }
  }, [dataConfig, isSuccess]);

  const onSaveTimer = () => {
    showcaseService.updateConfiguration(headerValue, timerValue).then(
      () => {
        queryClient.invalidateQueries("showcaseConfig").then();
      }
    )
  }


  return (
    <div style={{display: "flex", justifyContent: "center"}}>
      <div style={{display: "flex", flexDirection: "column", gap: "40px", minWidth: "1200px"}}>
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            boxShadow: "none",
            border: "1px solid #e7e5e4",
            padding: "16px"
        }}>
          <div style={{display: "flex", flexDirection: "row", gap: "8px", alignItems: "center",}}>
            <TextField
              autoFocus
              margin="dense"
              label="Заголовок"
              variant="standard"
              value={headerValue}
              onChange={(event) => setHeaderValue(event.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              type="number"
              label="Таймер"
              variant="standard"
              value={timerValue}
              onChange={(event) => setTimerValue(event.target.value)}
            />
            <Button
              variant="contained"
              disabled={dataConfig?.data.timer === timerValue && dataConfig?.data.header === headerValue}
              onClick={onSaveTimer}
            >
              Сохранить
            </Button>
          </div>
          <Button variant="contained" onClick={handleClickOpen}>
            Создать
          </Button>
        </Card>
        <Card style={{padding: "20px", boxShadow: "none", border: "1px solid #e7e5e4"}}>
          {
            data?.data.total > 0 ? (
              <Grid container spacing={4} >
                {data?.data.results.map(
                  (showcase) => (
                    <Grid item key={showcase.id} xs={12} sm={6} md={3}>
                      <ShowcaseCard showcase={showcase}/>
                    </Grid>
                  )
                )}
              </Grid>
            )
              :
              <Typography variant="h5" component="div" style={{textAlign: "center"}}>
                Нет данных
              </Typography>
          }
        </Card>
        {
          data?.data.total > 0 && (
            <Pagination
              count={Math.ceil(data?.data.total / 4)}
              page={page}
              onChange={(event, value) => setPage(value)}
              style={{display: "flex", justifyContent: "center", marginTop: "20px"}}
            />
          )

        }
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData).entries());
            const name = formJson.name;
            const loanAmount = formJson.loan_amout;
            const rate = formJson.rate;
            const url = formJson.url;
            showcaseService.create(name, loanAmount, rate, url).then(
              () => {
                queryClient.invalidateQueries("showcases").then(
                  () => handleClose()
                );
              }
            )
          },
        }}
      >
        <DialogTitle>Создание Витрины</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Название"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="loan_amout"
            name="loan_amout"
            type="number"
            label="Сумма займа"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="rate"
            name="rate"
            type="number"
            label="Процентная ставка"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="url"
            name="url"
            label="Ссылка"
            fullWidth
            variant="standard"
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отменить</Button>
          <Button type="submit">Сохранить</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default ShowcasesPage;