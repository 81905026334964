import CustomDateRangePicker from "../components/DateRangePicker";
import { LineChart } from "../components/LineChart";
import Selector from "../components/Selector";
import CustomTabs from "../components/Tabs";
import {useState, useMemo} from "react";
import {useQuery} from "react-query";
import contractorService from "../services/contractor.service";
import {cpaService} from "../services/cpa.service";
import statisticsService from "../services/statistics.service";

function OutcomingTraffic() {
  const endDate = new Date();
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  endDate.setMilliseconds(59);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
    endDate: endDate,
    key: "selection",
  });
  const [contractor, setContractor] = useState([]);
  const {data: contractorsData, isSuccess: contractorsIsSuccess} = useQuery(
    {queryKey: ["contractors"],
      queryFn: () => contractorService.getAll()}
  )

  const contractors = useMemo(
    () => {
      if (!contractorsIsSuccess) return []
      return contractorsData?.data.map((contractor) => ({
        id: contractor.id,
        name: contractor.name,
      }))
    }, [contractorsIsSuccess, contractorsData?.data]
  )

  const {data, isSuccess} = useQuery(
    ["form", null, "chart", selectionRange, contractor],
    () => cpaService.getChart(null, selectionRange, contractor.map((c) => c.id))
  )
  const labels = useMemo(
    () => {
      if (!isSuccess) return []
      return Object.keys(data?.data)
    }, [isSuccess, data?.data]
  )

  const datasets = useMemo(
    () => {
      if (!isSuccess) return []
      const sent = Object.values(data?.data).map((obj) =>
        obj["sent"] ? obj["sent"] : 0
      );
      const approved = Object.values(data?.data).map((obj) =>
        obj["approved"] ? obj["approved"] : 0
      );
      const rejected = Object.values(data?.data).map((obj) =>
        obj["rejected"] ? obj["rejected"] : 0
      );
      const submitted = Object.values(data?.data).map((obj) =>
        obj["submitted"] ? obj["submitted"] : 0
      );
      const pending = Object.values(data?.data).map((obj) =>
        obj["pending"] ? obj["pending"] : 0
      );

      return [
        {
          label: "Общее кол-во заявок",
          data: sent,
          borderColor: "rgb(199, 228, 42)",
          backgroundColor: "rgba(199, 228, 42, 0.5)",
        },
        {
          label: "Кол-во заявок в ожидании",
          data: pending,
          borderColor: "rgb(203, 62, 175)",
          backgroundColor: "rgba(203, 62, 175, 0.5)",
        },
        {
          label: "Кол-во одобренных заявок",
          data: approved,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          label: "Кол-во выданных заявок",
          data: submitted,
          borderColor: "rgb(149, 235, 109)",
          backgroundColor: "rgba(149, 235, 109, 0.5)",
        },
        {
          label: "Кол-во отвергнутых заявок",
          data: rejected,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ]
    }, [isSuccess, data?.data]
  )

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <LineChart label="Исходящий трафик" datasets={datasets} labels={labels} />
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          justifyContent: "flex-end",
          marginTop: 60,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <Selector
            label="Контрагенты"
            options={contractors}
            option={contractor}
            setOption={setContractor}
          />
          {/* <Selector
              label="Статус"
              options={statusOptions}
              option={status}
              setOption={setStatus}
            /> */}
        </div>

        <CustomDateRangePicker
          selectionRange={selectionRange}
          setSelectionRange={setSelectionRange}
        />
      </div>
    </div>
  );
}

function IncomingTraffic() {
  const endDate = new Date();
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  endDate.setMilliseconds(59);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
    endDate: endDate,
    key: "selection",
  });

  const {data, isSuccess} = useQuery(
    ["incomingTraffic", selectionRange],
    () => statisticsService.getIncomingTraffic(selectionRange)
  )
  const labels = useMemo(
    () => {
      if (!isSuccess) return []
      return Object.keys(data?.data)
    }, [isSuccess, data?.data]
  )

  const datasets = useMemo(
    () => {
      if (!isSuccess) return []
      return [
        {
          label: "Новые пользователи",
          data: Object.values(data?.data).map((obj) =>
            obj.length > 0
              ? obj.filter((val) => val.type === "user_created")[0].count
              : 0
          ),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ]
    }, [isSuccess, data?.data]
  )

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <LineChart label="Входящий трафик" datasets={datasets} labels={labels} />
      <CustomDateRangePicker
        selectionRange={selectionRange}
        setSelectionRange={setSelectionRange}
      />
    </div>
  );
}

function TrafficPage() {
  const labels = ["Входящий трафик", "Исходящий трафик"];
  const elements = [IncomingTraffic, OutcomingTraffic];
  return (
    <div>
      <CustomTabs labels={labels} elements={elements} />
    </div>
  );
}

export default TrafficPage;
