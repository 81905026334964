import { axiosClassic, axiosWithAuth } from "../api/interceptors";
import {removeFromStorage, saveTokenStorage} from "./cookie.service";

export const authService = {
  async getNewTokensWithSave() {
    const response = await axiosClassic.post("/auth/refresh-token");

    if (response.data.access_token) saveTokenStorage(response.data.access_token);

    return response;
  },
  async getNewTokens() {
    return await axiosWithAuth.post("/auth/refresh-token");
  },
  async login(username, password) {
    const response = await axiosClassic.post(
      `/auth/login`,
        {
          username: username,
          password: password
        },
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
    );

    if (response.data.access_token) saveTokenStorage(response.data.access_token);

    return response;
  },
  async logout() {
    removeFromStorage();
    return await axiosClassic.post("/auth/logout");
  }
};
