import {axiosWithAuth} from "../api/interceptors";


export default class formService {
  static async get(formId) {
    return axiosWithAuth.get(`/form?form_id=${formId}&entities=conversion&entities=conversion.stats`);
  }
  static async getAll(
    page, selectionRange, perPage = 25, order = "asc", sort = "id"
  ) {
    return axiosWithAuth.get(`/form/list`,
      {
        params: {
          page,
          per_page: perPage,
          sort,
          order,
          start_date: new Date(selectionRange.startDate).toISOString(),
          end_date: new Date(selectionRange.endDate).toISOString(),
          entities: ["conversion", "conversion.stats"],
        },
        paramsSerializer: {
          indexes: null
        }
      }
    );
  }
  static async getAllByUserId(
    userId, page, perPage = 25, order = "asc", sort = "id"
  ) {
    return axiosWithAuth.get(`/users/${userId}/forms`,
      {
        params: {
          page,
          per_page: perPage,
          sort,
          order,
          entities: ["conversion", "conversion.stats"],
        },
        paramsSerializer: {
          indexes: null
        }
      }
    );
  }
  static async getSteps() {
    return axiosWithAuth.get(`/form/steps`)
  }

  static async updateStep(stepId, name, enabled) {
    const data = {
      name: name,
      enabled: enabled,
    }

    return axiosWithAuth.patch(`/form/steps/${stepId}`, data)
  }
}
