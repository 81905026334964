import * as React from "react";
import { styled, } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import StorefrontIcon from '@mui/icons-material/Storefront';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet } from "react-router-dom";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import TimelineIcon from "@mui/icons-material/Timeline";
import GetAppIcon from "@mui/icons-material/GetApp";
import SendIcon from "@mui/icons-material/Send";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";

import "../index.css";
import { useEffect } from "react";

import { useLocation } from "react-router-dom";
import {getAccessToken, removeFromStorage} from "../services/cookie.service";


const pages = {
  "Воронки": {
    icon: (props) => <FilterAltIcon {...props} />,
    url: "/hopper",
  },
  "Трафик": {
    icon: (props) => <SwapHorizIcon {...props} />,
    url: "/traffic",
  },
  "Статистика по заявкам": {
    icon: (props) => <TimelineIcon {...props} />,
    url: "/app-stats",
  },
  "Список пользователей": {
    icon: (props) => <SupervisorAccountIcon {...props} />,
    url: "/users-list",
  },
  "Рассылка сообщений": {
    icon: (props) => <SendIcon {...props} />,
    url: "/broadcast",
  },
  "Экспорт в Excel": {
    icon: (props) => <GetAppIcon {...props} />,
    url: "/export",
  },
  "Список шагов анкеты": {
    icon: (props) => <FormatListBulletedIcon {...props} />,
    url: "/form-steps",
  },
  "Витрина": {
    icon: (props) => <StorefrontIcon {...props} />,
    url: "/showcases",
  },
  "Анкета": {
    icon: (props) => <GetAppIcon {...props} />, // doesn't matter, we dont render this,
    url: "app",
  },
  "Пользователь": {
    icon: (props) => <GetAppIcon {...props} />, // doesn't matter, we dont render this,
    url: "user",
  },
};

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function PageLayout() {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleMouseEnter = () => {
    if (!open) setOpen(true);
  };

  const handleMouseLeave = () => {
    if (open) setOpen(false);
  };

  const location = useLocation();


  useEffect(() => {
    if (!getAccessToken()) navigate("auth");
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            {
              Object.keys(pages).filter((key) =>
                location.pathname.includes(pages[key]["url"])
              )[0]
            }
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        // onMouseEnter={() => handleMouseEnter()}
        // onMouseLeave={() => handleMouseLeave()}
        variant="permanent"
        open={open}
      >
        <DrawerHeader></DrawerHeader>
        <Divider />
        <List>
          <ListItem
            key={"Закрыть"}
            disablePadding
            sx={{ display: "block" }}
            onClick={() => (open ? handleMouseLeave() : handleMouseEnter())}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <MenuIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Закрыть"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          {[
            "Воронки",
            "Трафик",
            "Статистика по заявкам",
            "Список пользователей",
            "Список шагов анкеты",
            "Витрина"
          ].map((text, index) => (
            <a href={pages[text].url}>
              <ListItem key={text} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {pages[text].icon({
                      color: !location.pathname.includes(pages[text].url)
                        ? "action"
                        : "primary",
                    })}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </a>
          ))}
        </List>
        <Divider />
        <List>
          {["Рассылка сообщений", "Экспорт в Excel"].map((text, index) => (
            <a href={pages[text].url}>
              <ListItem key={text} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {pages[text].icon({
                      color: !location.pathname.includes(pages[text].url)
                        ? "action"
                        : "primary",
                    })}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </a>
          ))}
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              removeFromStorage()
              navigate("auth");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LogoutIcon color="error" />
              </ListItemIcon>
              <ListItemText primary={"Выход"} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <main>
          <Outlet />
        </main>
      </Box>
    </Box>
  );
}
