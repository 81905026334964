import {useNavigate, useParams} from "react-router-dom";
import {useMemo, useState} from "react";
import CustomTable from "../components/CustomTable";
import Selector from "../components/Selector";
import CustomDateRangePicker from "../components/DateRangePicker";
import CustomTabs from "../components/Tabs";
import {LineChart} from "../components/LineChart";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {useQuery} from "react-query";

import formService from "../services/form.service";
import contractorService from "../services/contractor.service";
import {cpaService} from "../services/cpa.service";
import dayjs from "dayjs";

function ChartEl({ selectionRange, setSelectionRange }) {
  const { id } = useParams();
  const {data, isSuccess} = useQuery(
    ["form", id, "chart", selectionRange],
    () => cpaService.getChart(id, selectionRange)
  )
  const labels = useMemo(
    () => {
      if (!isSuccess) return []
      return Object.keys(data?.data)
    }, [isSuccess, data?.data]
  )

  const datasets = useMemo(
    () => {
      if (!isSuccess) return []
      const sent = Object.values(data?.data).map((obj) =>
        obj["sent"] ? obj["sent"] : 0
      );
      const approved = Object.values(data?.data).map((obj) =>
        obj["approved"] ? obj["approved"] : 0
      );
      const rejected = Object.values(data?.data).map((obj) =>
        obj["rejected"] ? obj["rejected"] : 0
      );
      const submitted = Object.values(data?.data).map((obj) =>
        obj["submitted"] ? obj["submitted"] : 0
      );
      const pending = Object.values(data?.data).map((obj) =>
        obj["pending"] ? obj["pending"] : 0
      );

      return [
        {
          label: "Общее кол-во заявок",
          data: sent,
          borderColor: "rgb(199, 228, 42)",
          backgroundColor: "rgba(199, 228, 42, 0.5)",
        },
        {
          label: "Кол-во заявок в ожидании",
          data: pending,
          borderColor: "rgb(203, 62, 175)",
          backgroundColor: "rgba(203, 62, 175, 0.5)",
        },
        {
          label: "Кол-во одобренных заявок",
          data: approved,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          label: "Кол-во выданных заявок",
          data: submitted,
          borderColor: "rgb(149, 235, 109)",
          backgroundColor: "rgba(149, 235, 109, 0.5)",
        },
        {
          label: "Кол-во отвергнутых заявок",
          data: rejected,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ]
    }, [isSuccess, data?.data]
  )

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <LineChart
        label="Статистика по заявкам"
        datasets={datasets}
        labels={labels}
      />
    </div>
  );
}

function TableEl({ selectionRange, setSelectionRange }) {
  const { id } = useParams();

  const labels = {
    id: "ID заявки",
    contractor: "Контрагент",
    status: "Статус",
    updated_at: "Время изм. статуса",
  };

  const statusOptions = [
    { id: 1, name: "Принята" },
    { id: 2, name: "Ожидает" },
    { id: 3, name: "Отклонена" },
  ];

  const translation = {
    pending: "Ожидание",
    sent: "Отправлена",
    approved: "Одобрена",
    submitted: "Выдана",
    rejected: "Отклонена",
  };

  const {data, isSuccess} = useQuery(
    ["cpa", id],
    () => cpaService.get(id),
  )

  const {data: contractorsData, isSuccess: contactorsSuccess} = useQuery({
    queryKey: ["contractors"],
    queryFn: () => contractorService.getAll(),
    initialData: []
  })

  const items = useMemo(
    () => {
      if (!contactorsSuccess || !isSuccess) return []
      const itemsOut = []

      Object.values(data?.data).map((items) => {
        items.map((item) => (
          itemsOut.push({
            ...item,
            contractor: contractorsData?.data.filter((obj) => obj["id"] == item["contractor_id"])?.[0]?.name,
            status: translation[item["stats"]?.[item["stats"]?.length - 1]?.["type"]],
            updated_at: dayjs(new Date(item["stats"]?.[item["stats"]?.length - 1]?.["updated_at"])).format("DD.MM.YYYY HH:mm"),
          })
        ));
      })

      return itemsOut
    },
    [contractorsData?.data, translation, contactorsSuccess, isSuccess, data]
  )

  return (
    <div style={{ paddingBottom: 400 }}>
      {items.length > 0 ? (
        <CustomTable
          touchable={false}
          to="cpa"
          labels={labels}
          data={items}
        />
      ) : (
        <h1>Нет заявок</h1>
      )}
    </div>
  );
}

function ApplicationPage() {
  const { id } = useParams();

  const labels = ["Таблица", "График"];
  const navigate = useNavigate();
  const [contractor, setContractor] = useState([]);

  const endDate = new Date();

  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  endDate.setMilliseconds(59);

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
    endDate: endDate,
    key: "selection",
  });

  const {data} = useQuery(
    ["form", id],
    () => formService.get(id),
  )

  const {data: contractorsData, isSuccess: contractorsIsSuccess} = useQuery(
    {queryKey: ["contractors"],
      queryFn: () => contractorService.getAll()}
  )

  const contractors = useMemo(
    () => {
      if (!contractorsIsSuccess) return []
      return contractorsData?.data.map((contractor) => ({
        id: contractor.id,
        name: contractor.name,
      }))
    }, [contractorsIsSuccess, contractorsData?.data]
  )

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const elements = [TableEl, ChartEl];

  return (
    <div style={{ textAlign: "start" }}>
      <ListItemIcon
        onClick={() => handleBackClick()}
        sx={{ textAlign: "start" }}
      >
        <ArrowBackIosNewIcon fontSize="medium" /> Назад
      </ListItemIcon>
      <div style={{ width: "80%", textAlign: "start" }}>
        <p>ID Анкеты: {id}</p>
        {data?.data.created_at ? (
          <p>
            Дата создания: {new Date(data?.data.created_at).toLocaleDateString()}{" "}
            {new Date(data?.data.created_at).toLocaleTimeString()}
          </p>
        ) : (
          <></>
        )}
        {data?.data.updated_at ? (
          <p>
            Дата обновления:{" "}
            {new Date(data?.data.updated_at).toLocaleDateString()}{" "}
            {new Date(data?.data.updated_at).toLocaleTimeString()}
          </p>
        ) : (
          <></>
        )}
        <p>Отправлена: {data?.data.sent ? "Да" : "Нет"}</p>
        {data?.data.sent_at ? (
          <p>
            Дата отправки: {new Date(data?.data.sent_at).toLocaleDateString()}{" "}
            {new Date(data?.data.sent_at).toLocaleTimeString()}
          </p>
        ) : (
          <></>
        )}
        <p>Завершена: {data?.data.completed ? "Да" : "Нет"}</p>
        {data?.data.completed_at ? (
          <p>
            Дата завершения:{" "}
            {new Date(data?.data.completed_at).toLocaleDateString()}{" "}
            {new Date(data?.data.completed_at).toLocaleTimeString()}
          </p>
        ) : (
          <></>
        )}

        <p>Активна: {data?.data.is_live ? "Да" : "Нет"}</p>
        {data?.data.live_at ? (
          <p>
            Дата активности: {new Date(data?.data.live_at).toLocaleDateString()}{" "}
            {new Date(data?.data.live_at).toLocaleTimeString()}
          </p>
        ) : (
          <></>
        )}
        <br />
        <p>Текущий прогресс заполнения: {data?.data.current_step}</p>
        <p>Общий прогресс заполнения: {data?.data.max_current_step}</p>
        <p>Максимальный этап: {data?.data.steps_count}</p>
        <br />
        <p>Серия паспорта: {data?.data.data?.passport?.series}</p>
        <p>Номер паспорта: {data?.data.data?.passport?.number}</p>
        <p>Паспорт выдан: {data?.data.data?.passport_issued_by?.name}</p>
        <p>Код отдела выдачи паспорта: {data?.data.data?.passport_issued_by?.code}</p>
        <p>
          Дата выдачи паспорта:{" "}
          {new Date(data?.data.data?.passport_issued_date).toLocaleDateString()}
        </p>
        <p>ИНН: {data?.data.data?.inn}</p>
        <p>СНИЛС: {data?.data.data?.snils}</p>
        <br />
        <p>Имя: {data?.data.data.fio?.first_name}</p>
        <p>Фамилия: {data?.data.data?.fio?.last_name}</p>
        <p>Отчество: {data?.data.data?.fio?.patronymic}</p>
        <p>Пол: {data?.data.data?.gender == "female" ? "Женский" : "Мужской"}</p>
        <p>
          Дата рождения:{" "}
          {new Date(data?.data.data?.birth_date).toLocaleDateString()}
        </p>
        <p>Телефон: {data?.data.data?.phone}</p>
        <p>Email: {data?.data.data?.email}</p>

        <br />
        <p>Базовый доход: {data?.data.data?.basic_income}</p>
        <p>Дополнительный доход: {data?.data.data?.add_income}</p>
        <p>Платежи по кредитам: {data?.data.data?.credit_payments}</p>
        <p>Траты на жилье: {data?.data.data?.house_expenses}</p>
        <p>Траты на еду: {data?.data.data?.food_expenses}</p>
        <p>Прочие траты: {data?.data.data?.other_expenses}</p>

        <br />
        <p>Сумма займа: {data?.data.data?.loan_amount}</p>
        <p>Срок займа: {data?.data.data?.loan_days} дн.</p>

        <br />

        <h2>Адрес организации</h2>
        <p>Название организации: {data?.data.data?.organization_name}</p>
        <p>Контактный телефон: {data?.data.data?.organization_phone}</p>
        <p>Область (регион): {data?.data.data?.organization_address?.region}</p>
        <p>Город: {data?.data.data?.organization_address?.city}</p>
        <p>Улица: {data?.data.data?.organization_address?.street}</p>
        <p>Дом: {data?.data.data?.organization_address?.house}</p>
        <p>Корпус: {data?.data.data?.organization_address?.housing}</p>
        <p>Квартира: {data?.data.data?.organization_address?.apartment}</p>

        <br />

        <h2>Регистрационный адрес</h2>
        <p>Область (регион): {data?.data.data?.registration_address?.region}</p>
        <p>Город: {data?.data.data?.registration_address?.city}</p>
        <p>Улица: {data?.data.data?.registration_address?.street}</p>
        <p>Дом: {data?.data.data?.registration_address?.house}</p>
        <p>Корпус: {data?.data.data?.registration_address?.housing}</p>
        <p>Квартира: {data?.data.data?.registration_address?.apartment}</p>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "80%" }}>
          <CustomTabs
            selectionRange={selectionRange}
            setSelectionRange={contractor}
            labels={labels}
            elements={elements}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "flex-end",
            marginTop: 60,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <Selector
              label="Контрагенты"
              options={contractors}
              option={contractor}
              setOption={setContractor}
            />
            {/* <Selector
              label="Статус"
              options={statusOptions}
              option={status}
              setOption={setStatus}
            /> */}
          </div>

          <CustomDateRangePicker
            selectionRange={selectionRange}
            setSelectionRange={setSelectionRange}
          />
        </div>
      </div>
    </div>
  );
}

export default ApplicationPage;
