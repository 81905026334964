import React from 'react';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {useQuery, useQueryClient} from "react-query";
import formService from "../services/form.service";
import {Switch} from "@mui/material";

const StepsPage = () => {
  const queryClient = useQueryClient()

  const {data} = useQuery(
    ["steps"],
    () => formService.getSteps()
  )

  const updateStep = (stepId, name, enabled) => {
    formService.updateStep(stepId, name, enabled).then(
      r => queryClient.invalidateQueries("steps")
    )
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <TableContainer component={Paper} style={{maxWidth: "1200px"}}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{padding: "8px"}}>ID</TableCell>
              <TableCell align="center" style={{padding: "8px"}}>Название</TableCell>
              <TableCell align="center" style={{padding: "8px"}}>Статус</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data.map((step) => (
              <TableRow key={step.id}>
                <TableCell align="left" style={{padding: "8px"}}>{step.id}</TableCell>
                <TableCell align="center" style={{padding: "8px"}}>{step.name}</TableCell>
                <TableCell align="center" style={{padding: "8px"}}>
                  <Switch
                    checked={step.enabled}
                    onChange={(e) => updateStep(step.id, step.name, e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default StepsPage;