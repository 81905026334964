import { axiosWithAuth } from "../api/interceptors";
import dayjs from "dayjs";

export const cpaService = {
  async get(formId) {
    return axiosWithAuth.get(`/cpa?form_id=${formId}&include_stats=true`, {
      params: {
        include_stats: true
      },
      paramsSerializer: {
        indexes: null
      }
    })
  },
  async getChart(formId, selectionRange, contractors) {
    return axiosWithAuth.get(`/cpa/statistics/all/date-range`, {
      params: {
        start_date: dayjs(new Date(selectionRange.startDate)).format("YYYY-MM-DD"),
        end_date: dayjs(new Date(selectionRange.endDate)).format("YYYY-MM-DD"),
        contractor_ids: contractors,
        form_id: formId
      },
      paramsSerializer: {
        indexes: null
      }
    })
  },
}
