import {axiosWithAuth} from "../api/interceptors";


export default class userService {
  static async get(userId) {
    return axiosWithAuth.get(`/users/${userId}`,
      {
        params: {
          by_telegram: false
        }
      }
    );
  }
  static async getAll(page, perPage= 16, order = "asc", sort = "id") {
    return axiosWithAuth.get(`/users`,
      {
        params: {
          page,
          per_page: perPage,
          sort,
          order
        }
      }
    );
  }
}
