import {axiosWithAuth} from "../api/interceptors";


export default class showcaseService {
  static async getAll(
    page, perPage = 8, order = "asc", sort = "id"
  ) {
    return axiosWithAuth.get(`/showcase`,
      {
        params: {
          page,
          per_page: perPage,
          sort,
          order,
        },
        paramsSerializer: {
          indexes: null
        }
      }
    );
  }
  static async create(name, loanAmount, rate, url) {
    const data = {
      name: name,
      loan_amount: loanAmount,
      rate: rate,
      url: url,
    }

    return axiosWithAuth.post(`/showcase`, data)
  }

  static async update(showcaseId, name, loanAmount, rate, url) {
    const data = {
      name: name,
      loan_amount: loanAmount,
      rate: rate,
      url: url,
    }

    return axiosWithAuth.patch(`/showcase/${showcaseId}`, data)
  }
  static async delete(showcaseId) {
    return axiosWithAuth.delete(`/showcase/${showcaseId}`)
  }
  static async getConfiguration() {
    return axiosWithAuth.get(`/showcase-settings`)
  }
  static async updateConfiguration(header, timer) {
    const data = {
      header: header,
      timer: timer
    }
    return axiosWithAuth.patch(`/showcase-settings`, data)
  }
}
