import { useParams } from "react-router-dom";
import {useMemo, useState} from "react";
import CustomTable from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Button from "@mui/material/Button";
import userService from "../services/user.service";
import {useQuery} from "react-query";
import formService from "../services/form.service";

function UserPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const {data} = useQuery(
    ["user", id],
    () => userService.get(id),
  )

  const {data: dataForm} = useQuery(
    ["userForms", id],
    () => formService.getAllByUserId(id, page),
  )

  const forms = useMemo(
    () => dataForm?.data.results.map((form) => ({
        ...form,
        sent: form.stats?.sent,
        pending: form.stats?.pending,
        approved: form.stats?.approved,
        rejected: form.stats?.rejected,
      })
    ),
    [dataForm?.data.results]
  )

  const labels = {
    id: "ID анкеты",
    sent: "Создано заявок",
    pending: "Ожидает",
    approved: "Одобрено",
    rejected: "Отклонено",
  };

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };
  return (
    <div style={{ textAlign: "start" }}>
      <ListItemIcon
        onClick={() => handleBackClick()}
        sx={{ textAlign: "start" }}
      >
        <ArrowBackIosNewIcon fontSize="medium" /> Назад
      </ListItemIcon>
      <div style={{textAlign: "left"}}>
        <p>ID: {data?.data.id}</p>
        <p>Telegram username: {data?.data.username}</p>
        <p>Telegram ID: {data?.data.telegram_id}</p>
        <p>Имя: {data?.data.first_name}</p>
        <p>Фамилия: {data?.data.last_name}</p>
        <p>
          Дата регистрации: {new Date(data?.data.created_at).toLocaleDateString()}
        </p>
        <p>
          Дата обновления: {new Date(data?.data.updated_at).toLocaleDateString()}
        </p>
        <p>Пройдена каптча: {data?.data.is_captcha_passed ? "Да" : "Нет"}</p>
      </div>
      {dataForm?.data.total > 0 ? (
        <CustomTable
          touchable={true}
          to="app"
          labels={labels}
          data={forms.length > 0 ? forms : []}
        />
      ) : (
        <h1>Нет анкет</h1>
      )}
      {page < 1 ? (
        <Button
          sx={{ marginTop: "40px" }}
          size="large"
          onClick={() => setPage(page + 1)}
          variant="outlined"
        >
          Загрузить еще
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
}

export default UserPage;
