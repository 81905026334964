import React, {useMemo, useState} from "react";
import CustomTable from "../components/CustomTable";

import Button from "@mui/material/Button";
import {useQuery} from "react-query";
import userService from "../services/user.service";
import dayjs from "dayjs";
import {Pagination} from "@mui/material";

function UsersListPage() {
  const labels = {
    id: "ID пользователя",
    fullname: "ФИО",
    username: "Telegram",
    createdAt: "Дата регистрации",
  };
  const [page, setPage] = useState(1);
  const {data, isLoading} = useQuery(
    ["users", page],
    () => userService.getAll(page),
  )

  const users = useMemo(
    () => data?.data.results.map((user) => ({
        ...user,
        fullname: `${user.first_name ? user.first_name : ""} ${
          user.last_name ? user.last_name : ""
        }`,
        createdAt: dayjs(new Date(user.created_at)).format("DD.MM.YYYY"),
      })),
    [data?.data.results]
  )

  return (
    <div style={{display: "flex", flexDirection: "column", gap: "16px"}}>
      <p style={{textAlign: "start", marginLeft: 10}}>
        Всего пользователей: {data?.data.total}
      </p>
      {data?.data.total > 0 ? (
        <CustomTable touchable={true} to="user" labels={labels} data={users}/>
      ) : (
        <h1>Нет пользователей</h1>
      )}
      z
    </div>
  )
}

export default UsersListPage;
